<template>
  <!--srcviewscompanylistvuehtmlStart-->

  <div id="defaultId1" name="header">
    <content_view id="g1c850" ref="contentView">
      <!-- 工具栏 插槽-->
      <template slot="header">
        <div id="gd2da8" class="uVNPU6M">
          <tools id="g9a4e3" class="u35ff0" :config="$store.state.app.toolsConfig" @clickMenu="clickMenu" ref="tools">
          </tools>
        </div>
      </template>
      <!-- 公司 -->
      <template slot="remark">
        <div id="c38042" :style="{
          'background-color': $store.state.app.themeColor,
        }" class="uc46ba">
          <div id="g1be3e" class="u7e732">
            <div id="gc4ae7"></div>
          </div>
          <!-- table -->
          <gui_scroll_x id="c3b019" class="ubfccf">
            <div id="c93b1a" v-for="(item, index) in tags"
              :class="item.labelCode == $store.state.app.labelCode ? $store.state.app.searchType == 'risk' ? 'ml-2 utPssIF Fml-2-active' : 'ml-2 utPssIF Fml-1-active' : 'ml-2 utPssIF'"
              :key="index" :closable="true" type="info">
              <span
                @click="powerArrLaber[0]?.hasPrivilege ? tagsClick(item) : $bus.$emit('show-permission-dialog', '您没有权限访问该功能！')"
                id="ca3dc2" class="uPrTsX7">{{ item.labelDescription }} </span>

            </div>
          </gui_scroll_x>
          <div id="ga24b1" class="u62ed9"></div>
          <div id="ga56af" class="u18679">
            <svg id="c5d772" class="icon uupMxrD" t="1710138718004" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="6769" width="20" height="20">
              <path id="c8c86f"
                d="M913.92 1016.32H354.56a31.36 31.36 0 0 1 0-64h559.36a31.36 31.36 0 0 0 31.36-31.36V569.6a31.36 31.36 0 0 1 64 0v352.64a94.08 94.08 0 0 1-95.36 94.08z"
                fill="#FFF" p-id="6770"></path>
              <path id="c5cf1a"
                d="M761.6 844.8H113.28A94.08 94.08 0 0 1 19.2 750.72V103.04A94.08 94.08 0 0 1 113.28 8.96h547.84a31.36 31.36 0 1 1 0 64H113.28a31.36 31.36 0 0 0-31.36 31.36v646.4a31.36 31.36 0 0 0 31.36 31.36H761.6a31.36 31.36 0 0 0 30.72-31.36V569.6a32 32 0 0 1 64 0v181.12a94.08 94.08 0 0 1-94.72 94.08z"
                fill="#FFF" p-id="6771"></path>
              <path id="ce04c5" v-show="isOpenSlct"
                d="M913.92 1016.32H301.44a31.36 31.36 0 0 1 0-64h612.48a31.36 31.36 0 0 0 31.36-31.36V569.6a31.36 31.36 0 0 1 64 0v352.64a94.08 94.08 0 0 1-95.36 94.08zM482.56 581.76a30.72 30.72 0 0 1-22.4-10.24L245.12 344.32a31.36 31.36 0 0 1 0-44.16 30.72 30.72 0 0 1 44.16 0l192 203.52L960 29.44a31.36 31.36 0 0 1 44.16 0 30.08 30.08 0 0 1 0 44.16L504.32 572.16a28.8 28.8 0 0 1-21.76 9.6z"
                fill="#FFF" p-id="6772"></path>
            </svg>
            <svg id="c502e7" class="icon" t="1709802263094" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="11088" width="20" height="20">
              <path id="c08cc9"
                d="M741.248 79.68l-234.112 350.08v551.488l55.296 24.704v-555.776l249.152-372.544c8.064-32.96-10.496-59.712-41.152-59.712h-709.248c-30.464 0-49.28 26.752-41.344 59.712l265.728 372.544v432.256l55.36 24.704v-478.592l-248.896-348.864h649.216z m-68.032 339.648c0-16.832 12.096-30.592 27.264-30.848h277.888c15.232 0 27.712 13.824 27.712 30.848s-12.416 30.848-27.712 30.848h-277.888c-15.168-0.32-27.264-14.016-27.264-30.848z m0 185.216c0-16.832 12.096-30.592 27.264-30.848h277.888c15.232 0 27.712 13.824 27.712 30.848s-12.416 30.848-27.712 30.848h-277.888c-15.168-0.256-27.264-14.016-27.264-30.848z m0 185.28c0-16.832 12.096-30.592 27.264-30.848h277.888c15.232 0 27.712 13.824 27.712 30.848s-12.416 30.848-27.712 30.848h-277.888c-15.168-0.32-27.264-13.952-27.264-30.848z"
                p-id="11089" fill="#FFF"></path>
            </svg>
          </div>
        </div>
      </template>
      <!-- 获课 -->
      <template slot="leads">
        <div id="c38042" :style="{
          'background-color': $store.state.app.themeColor,
        }" class="uc46ba">
          <div id="g1be3e" class="SavedAnswers">
            <div id="gc4ae7">My Saved Answers</div>
          </div>
          <!-- table -->
          <gui_scroll_x id="c3b019" class="ubfccf">
            <div id="c93b1a" v-for="(item, index) in leadesTags"
              :class="item.id == $store.state.app.SeachLabels ? 'ml-2 utPssIF Fml-2-active' : 'ml-2 utPssIF'"
              :key="index" :closable="true" type="info">
              <span
                @click="powerArrLaber[0]?.hasPrivilege ? labelstagsClick(item) : $bus.$emit('show-permission-dialog', '您没有权限访问该功能！')"
                id="ca3dc2" class="uPrTsX7">{{ item.title }} </span>

              <svg t="1733888388675" @click="deledeLaeadstags(item)" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="9598" width="20" height="20">
                <path
                  d="M900.435 310.459c-21.23-50.214-51.645-95.288-90.316-133.96s-83.745-69.084-133.96-90.315c-51.982-21.99-107.167-33.11-164.035-33.11-56.87 0-112.137 11.12-164.12 33.11-50.213 21.147-95.286 51.56-134.042 90.232-38.671 38.672-69.084 83.745-90.317 133.959-21.99 52.066-33.11 107.25-33.11 164.12 0 56.87 11.12 112.137 33.11 164.119 21.232 50.214 51.646 95.285 90.317 133.958 38.672 38.671 83.745 69.084 133.958 90.316 51.983 21.99 107.168 33.11 164.12 33.11 56.869 0 112.053-11.12 164.035-33.11 50.212-21.232 95.286-51.645 133.958-90.316 38.671-38.673 69.085-83.744 90.315-133.958 21.99-51.983 33.112-107.165 33.112-164.12 0.085-56.87-11.036-112.053-33.025-164.035zM686.102 652.852c0 0.083 0 0.083 0 0-11.71 11.71-30.666 11.71-42.376 0l-135.308-134.97L373.366 653.19c-11.71 11.711-30.668 11.711-42.378 0-11.71-11.71-11.795-30.668-0.084-42.461l135.053-135.306-135.39-134.97c-11.71-11.711-11.71-30.667 0-42.378 11.71-11.71 30.667-11.71 42.377 0L508.25 433.128l135.054-135.307c11.71-11.71 30.667-11.71 42.377 0 11.71 11.711 11.71 30.669 0 42.378L550.712 475.421l135.305 135.053c11.796 11.711 11.796 30.751 0.085 42.378z"
                  p-id="9599" fill="#FFFFFF"></path>
              </svg>
            </div>
          </gui_scroll_x>
          <div id="ga24b1" class="u62ed9"></div>
          <div id="ga56af" class="u18679">
            <svg id="c5d772" class="icon uupMxrD" t="1710138718004" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="6769" width="20" height="20">
              <path id="c8c86f"
                d="M913.92 1016.32H354.56a31.36 31.36 0 0 1 0-64h559.36a31.36 31.36 0 0 0 31.36-31.36V569.6a31.36 31.36 0 0 1 64 0v352.64a94.08 94.08 0 0 1-95.36 94.08z"
                fill="#FFF" p-id="6770"></path>
              <path id="c5cf1a"
                d="M761.6 844.8H113.28A94.08 94.08 0 0 1 19.2 750.72V103.04A94.08 94.08 0 0 1 113.28 8.96h547.84a31.36 31.36 0 1 1 0 64H113.28a31.36 31.36 0 0 0-31.36 31.36v646.4a31.36 31.36 0 0 0 31.36 31.36H761.6a31.36 31.36 0 0 0 30.72-31.36V569.6a32 32 0 0 1 64 0v181.12a94.08 94.08 0 0 1-94.72 94.08z"
                fill="#FFF" p-id="6771"></path>
              <path id="ce04c5" v-show="isOpenSlct"
                d="M913.92 1016.32H301.44a31.36 31.36 0 0 1 0-64h612.48a31.36 31.36 0 0 0 31.36-31.36V569.6a31.36 31.36 0 0 1 64 0v352.64a94.08 94.08 0 0 1-95.36 94.08zM482.56 581.76a30.72 30.72 0 0 1-22.4-10.24L245.12 344.32a31.36 31.36 0 0 1 0-44.16 30.72 30.72 0 0 1 44.16 0l192 203.52L960 29.44a31.36 31.36 0 0 1 44.16 0 30.08 30.08 0 0 1 0 44.16L504.32 572.16a28.8 28.8 0 0 1-21.76 9.6z"
                fill="#FFF" p-id="6772"></path>
            </svg>
            <svg id="c502e7" class="icon" t="1709802263094" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="11088" width="20" height="20">
              <path id="c08cc9"
                d="M741.248 79.68l-234.112 350.08v551.488l55.296 24.704v-555.776l249.152-372.544c8.064-32.96-10.496-59.712-41.152-59.712h-709.248c-30.464 0-49.28 26.752-41.344 59.712l265.728 372.544v432.256l55.36 24.704v-478.592l-248.896-348.864h649.216z m-68.032 339.648c0-16.832 12.096-30.592 27.264-30.848h277.888c15.232 0 27.712 13.824 27.712 30.848s-12.416 30.848-27.712 30.848h-277.888c-15.168-0.32-27.264-14.016-27.264-30.848z m0 185.216c0-16.832 12.096-30.592 27.264-30.848h277.888c15.232 0 27.712 13.824 27.712 30.848s-12.416 30.848-27.712 30.848h-277.888c-15.168-0.256-27.264-14.016-27.264-30.848z m0 185.28c0-16.832 12.096-30.592 27.264-30.848h277.888c15.232 0 27.712 13.824 27.712 30.848s-12.416 30.848-27.712 30.848h-277.888c-15.168-0.32-27.264-13.952-27.264-30.848z"
                p-id="11089" fill="#FFF"></path>
            </svg>
          </div>
        </div>
      </template>

      <template slot="content_view">
        <router-view :key="this.refreshKey ? this.refreshKey : this.$route.fullPath" id="g9cbaf" class="u5eb45"
          ref="route" @setRowsSlct="setRowsSlct" @slctRows="slctRows">
        </router-view>
      </template>
      <!-- <template  slot="right_view_no">
        <div id="g4c0f7" class="u671e1_list">
          <img id="gc41cf" src="../../assets/images/erweima.png" alt="" />
          <div id="gd52b0" class="u92443">
            You are traveling let Company Search accompany you for free
          </div>
          <div id="gd3ca2" class="u13971">
            Scan QR code with mobile phone camera to download Company Search
            mobie version
          </div>
        </div>
      </template> -->
    </content_view>
    <PermissionDialog></PermissionDialog>
    <Login :loginAndCreatePop.sync="loginDialog"></Login>

    <el-dialog :visible.sync="deledeLaeadsDialog" center :close-on-click-modal="true" @close="deledeLaeadsDialog = false"
      custom-class="dialog_normalsuo" :append-to-body="true">
      <div>
        <div class="detail_cont">
          <h3 class="dialog_normal_question-head">Tips</h3>
          <div class="detail_cont_text " style="padding: 0px;">
            Are you sure to delete Saved Answer?
          </div>

        </div>
      </div>
      <div class="flex_center" style="margin-top: 20px;">
        <el-button class="btn_export riskButton leadsDialogD8850" @click="deledeLaeadsDialog = false">Cancel</el-button>
        <el-button class="btn_export riskButton questionExportButtonactive" style="margin-left: 30px;"
          @click="onSubmitLaeads()">Confirm</el-button>
      </div>
    </el-dialog>
  </div>
  <!--srcviewscompanylistvuehtmlEnd-->
</template>

<script>
import PermissionDialog from '@/components/power/PermissionDialog.vue';
import { mapGetters } from "vuex";
import Login from "@/components/login.vue";
import bus from "@/components/bus";
import { mixins1 } from "@/mixins/index";
import { getCommonPrivilegeModule, getCompanyLabelList, getAnswersHead, delAnswersHead } from "@/api/companyApi.js";

// interfaceCode
//importStart

import content_view from "../layout/content_view.vue";
import gui_tabs from "../../components/gui_tabs/gui_tabs.vue";
import gui_tab_item from "../../components/gui_tabs/gui_tab_item.vue";
import gui_collapse from "../../components/gui_collapse/gui_collapse.vue";
import gui_checkbox from "../../components/gui_checkbox/gui_checkbox.vue";
import gui_scroll_x from "../../components/gui_scroll_x/gui_scroll_x.vue";
import gui_table from "../../components/gui_table/gui_table.vue";
import { autoSortArr } from "../../utils/index.js";
import tools from "./component/tools.vue";

export default {
  mixins: [mixins1],
  name: "",
  props: {

  },
  created() {


    if (this.$route.name == 'riskNoParams') {
      this.$store.dispatch("app/setThemColor", "#8E86DD");
      this.$store.dispatch("app/setSearchType", "risk");
    } else {
      this.$store.dispatch("app/setThemColor", "#1290C9");
      this.$store.dispatch("app/setSearchType", "company");
    }
  },
  mounted() {
    this.token ? this.getCommonPrivilegeModule() : ''

  },
  components: {
    // tools start
    tools,
    // tools end
    Login,
    content_view,
    gui_tabs,
    gui_tab_item,
    gui_collapse,
    gui_checkbox,
    gui_scroll_x,
    gui_table,
    PermissionDialog
  },
  // 解决重复路由页面不刷新问题
  computed: {
    ...mapGetters(["refreshKey"]),
  },
  data() {
    return {
      loginDialog: false,
      token: localStorage.getItem("token"),

      isOpenSlct: false,
      deledeLaeadsDialog: false,
      Clearallflase: false,
      cascader6Value: "",
      cascader6Options: [],
      //

      //
      sortList: "",
      cascader6Props: {
        expandTrigger: "click",
        multiple: true,
        checkStrictly: false,
        emitPath: true,
        lazy: false,
        lazyLoad: () => { },
        value: "value",
        label: "label",
        children: "children",
        disabled: "disabled",
        leaf: "leaf",
        hoverThreshold: 500,
      },
      // sort_listProps
      sort_listProps: {
        expandTrigger: "click",
        multiple: true,
        checkStrictly: false,
        emitPath: true,
        lazy: false,
        lazyLoad: () => { },
        value: "value",
        label: "label",
        children: "children",
        disabled: "disabled",
        leaf: "leaf",
        hoverThreshold: 500,
      },
      // defaultColumns
      defaultColumns: [],
      // 工具设置

      // tags
      tags: [],
      // isShowMenu
      isShowMenu: true,
      // country_search_str
      country_search_str: "",
      // isShowCountryMore
      isShowCountryMore: false,
      leadesTags: [],
      // countryParams
      countryParams: [],
      // location_search_str
      location_search_str: "",
      // industry_search_str
      industry_search_str: "",
      //
      dateValue: "",
      // isShowCountryMore
      isShowCountryMore: false,
      // sanctionTypeList
      sanctionTypeList: [],
      // companyTypeParams
      companyTypeParams: [],
      page: {
        pageNo: 1,
        pageSize: 50,
        total: 0,
      },
      powerArrLaber: [],
      leadesitem: '',
      // srcviewscompanylistvueDataEnd
    };
  },
  methods: {
    // 删除保存条件
    async onSubmitLaeads() {
      let res = await delAnswersHead({ headId: this.leadesitem })
      if (res.code == 200) {
        this.getAnswersHeadList()
        this.$message({
          message: res.msg,
          center: true,
          type: "success",
        });
        this.deledeLaeadsDialog = false

      } else {
        this.$message({
          message: res.msg,
          center: true,
          type: "error",
        });
      }
    },
    deledeLaeadstags(item) {
      this.leadesitem = item.id
      this.deledeLaeadsDialog = true
    },
    tagsClick(value) {
      this.$store.dispatch("app/SetlabelCode", value.labelCode);
      this.$store.dispatch("app/SetlabelCodeName", value.labelDescription);
   
      this.$store.dispatch("app/SetarchagAin", true)
      this.$store.commit("setRefreshKey", new Date().getTime());
      this.$router.push({
        path: "/index/search/list/" + '' + "/company",
        query: {
          searchname: str,
          companyCountry: this.countryCode,
          keword: this.Keyword,
          companyCountryEn: this.countryCode1
        },
      });

    },
    labelstagsClick(value) {
      this.$store.dispatch("app/SetSeachLabels", value.id);
      this.$store.dispatch("app/SetSeachLabelsCodeName", value.title);

    },
    // 获取权限
    getCommonPrivilegeModule() {
      getCommonPrivilegeModule().then(res => {
        if (res.code == 200) {
          this.powerArrLaber = res.data.filter(item => item.id == 612)
          this.$store.dispatch("app/setPowerArr", res.data);
        }

      })
      this.getAnswersHeadList()
      // 获取tablist
      getCompanyLabelList().then(res => {
        if (res.code == 200) {
          this.tags = res.data
        }

      })
    },
    getAnswersHeadList() {
      // 获客列表
      getAnswersHead().then(res => {
        if (res.code == 200) {
          this.leadesTags = res.data
          this.$store.dispatch("app/SetleadesTags", res.data);

        }
      })
    },
    setRowsSlct(rows) {
      this.rowsSlct = rows;
    },
    // insertMethod
    // srcviewscompanylistvueMethodStart
    // default

    default() { }, // addColumnsDialog
    addColumnsDialog() {
      this.$refs.myTable.addColumnsDialog();
    }, // 点击菜单回调 控制检索
    clickMenu(name, data) {
      switch (name) {
        case "menu":
          this.$store.dispatch("app/toggleMenu", !this.$store.state.app.isMenu);
          break;
        case "Sort":
          this.$refs.route.Sort = data;
          break;
        case "Saved":
          this.$refs.route.Saved = data;
          break;
        case "select":
          this.$nextTick(() => {
            if (this.$refs.route.isOpenSlct) {
              this.$refs.route.isOpenSlct = false;
              this.$refs.route.isOpenSlct = false;
            } else {
              this.$refs.route.isOpenSlct = "Select";
              this.$refs.route.isOpenSlct = "Select";
            }
          });
          break;
        case "Compare":
          this.$nextTick(() => {
            if (this.$refs.route.Compare) {
              this.$refs.route.Compare = false;
            } else {
              this.$refs.route.Compare = true;
            }
          });
          break;
        case "Export":
          this.$nextTick(() => {
            if (this.$refs.route.Export) {
              this.$refs.route.Export = false;
            } else {
              this.$refs.route.Export = true;
            }
          });
          break;
        case "Update":
          this.$nextTick(() => {
            if (this.$refs.route.Update) {
              this.$refs.route.Update = false;
            } else {
              this.$refs.route.Update = true;
            }
          });
          break;
        case "Report":
          this.$nextTick(() => {
            if (this.$refs.route.ReportError) {
              this.$refs.route.ReportError = false;
            } else {
              this.$refs.route.ReportError = true;
            }
          });
          break;
        case "Clearall":
          this.$nextTick(() => {
            if (this.$refs.route.Clearallflase) {
              this.$refs.route.Clearallflase = false;
            } else {
              this.$refs.route.Clearallflase = true;
            }
          });

          break;
        case "ReSelectCriteria":
          this.$nextTick(() => {
            if (this.$refs.route.ReSelectCriteria) {
              this.$refs.route.ReSelectCriteria = false;
            } else {
              this.$refs.route.ReSelectCriteria = true;
            }
          });
          break;
        case "edit":
          this.$refs.route.columns = data;
          break;
      }
    }, // clearSearch
    clearSearch() {
      this.country_search_str = "";
      this.location_search_str = "";
      this.industry_search_str = "";
      this.countryParams.forEach((item) => (item.isChecked = false));
    }, // changeCountryMore
    changeCountryMore() {
      this.isShowCountryMore = !this.isShowCountryMore;
    }, // 所选列表数据
    slctRows(rowsSlct) {
    },
    // srcviewscompanylistvueMethodEnd
  },
};
</script>
<style scoped>
.srcviewscompanylistvuecssStart {}

.uVNPU6M {
  display: flex;
  align-items: center;
  width: 100%;
  height: 4em;
  margin-right: 0em;
  padding-right: 0em;
  padding-top: 0em;
  padding-bottom: 0em;
  margin-bottom: 0em;
  margin-top: 0em;
  margin-left: 0em;
}

.u35ff0 {
  width: 100%;
}

.uc46ba {
  display: flex;
  align-items: center;
  height: 4em;
  margin-top: 4em;
}

.u7e732 {
  width: 6.0em;
}

.ubfccf {
  width: 0;
  flex: auto;
}

.utPssIF {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4285em;
  padding-left: 0.7142857142857143em;
  background-color: rgba(255, 255, 255, 0.1);
  padding-right: 0.7142857142857143em;
  margin-right: 0.7142857142857143em;
  border-bottom-left-radius: 0.5em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-width: 0.07142857142857142em;
  cursor: pointer;
}

.uPrTsX7 {
  color: rgba(249, 249, 249, 1);
  font-size: 1.1428575rem !important;
  margin-right: 0.1em;
}

.u62ed9 {
  width: 5.3em;
}

.u18679 {
  display: none;
  height: 1.4285714285714286em;
}

.ue05ee {
  display: flex;
  align-items: center;
  height: 1.4285714285714286em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.u4a54e {
  flex: auto;
  margin-right: 0.5em;
  font-weight: 400;
}

.ua5ccf {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  border-top-left-radius: 100em;
  border-bottom-left-radius: 100em;
  border-top-right-radius: 100em;
  border-bottom-right-radius: 100em;
}

.uST8HpR {
  margin-top: -0.14285714285714285em;
}

.uc959b {
  padding-left: 0.3em;
  padding-right: 0.3em;
}

.utbR4NN {
  margin-left: 0.3em;
  margin-right: 0.3em;
  margin-top: 0.7em;
}

.ub891e {
  margin-top: 1.1em;
}

.ua155a {
  margin-bottom: 1.1em;
}

.u25681 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.u08e7b {
  border-bottom-style: solid;
  border-bottom-color: #1290c9;
  border-bottom-width: 0.07142857142857142em;
  color: rgb(18, 144, 201);
}

.u46077 {
  display: flex;
  flex-direction: column;
}

.u7cb9d {
  margin-bottom: 0.7142857142857143em;
}

.u3a348 {
  color: #1a2332;
  margin-bottom: 0.7142857142857143em;
  margin-top: 0.7142857142857143em;
  font-size: 1.1428571428571428em;
  line-height: 1.2857142857142858em;
  text-align: left;
  font-style: normal;
}

.u5d64c {
  width: 100%;
}

.ua475e {
  width: 100% !important;
}

.u8ca16 {
  position: relative;
}

/* 锁 */
.udb622 {
  /* display: flex; */
  display: none;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: rgba(254, 254, 254, 0.71);
  position: absolute;
  top: 0em;
  left: 0em;
}

.uvEJ7uc {
  margin-top: 8.928571428571429em;
}

.u5eb45 {
  align-items: center;
  height: 100%;
}

.u671e1_list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  padding-bottom: 0.5em;
  box-sizing: border-box;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-left: 0.5em;
}

.u92443 {
  text-align: center;
}

.u13971 {
  color: rgb(181, 182, 183);
  font-size: 0.8571428571428571em;
  text-align: center;
}

.utPssIF i {
  color: rgb(254, 3, 3);
}

.utPssIF span {}

.ua5ccf .roa90 {
  transform: rotate(180deg);
}

.uLFnHzK input {
  height: 2.571em;
  border-color: #cccccc;
}

.uLFnHzK input::placeholder {
  color: rgb(132, 151, 171);
}

.srcviewscompanylistvuecssEnd {}

.Fml-2-active {
  background-color: #685ED1;
  border: none;
  /* border-color: #DE6A2A; */

}

.Fml-1-active {
  /* background-color:#1290C9; */
  border: none;
}

.Fml-2-active span {
  /* color: #1A2332; */

}

.SavedAnswers {
  margin: 0 2.142857142857143em;
  font-size: 1.5714285714285714em;
  color: #FFFFFF;
}
</style>