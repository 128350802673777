<template>
    <!--srccomponentsgui_tablegui_tablevuehtmlStart-->

    <div id="defaultId1" class="  uefd81">
        <div id="c9e596" class="  ua9186">
            <div id="ca385d" v-if="false">
                <div id="ce9aa7">
                    <div id="c18b8c">
                        <ul id="c16cd6">
                            <li id="c56e35" v-for="(item, index) in items" :key="index" :draggable="true" @dragstart="startDrag(index)" @dragover="dragOver(index)" @drop="drop">{{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="c3ba0a" class="  u23a98">
                <el-button id="c64705" v-if="!isNoMy" type="primary" :disabled="rowsSlcts.length < 2">加入自选
                </el-button>
                <el-button id="c0471c" class="  u6edbf" type="success" :disabled="rowsSlcts.length < 2">比对
                </el-button>
                <div id="c1ebaf" class="  ud0598">选中了{{rowsSlcts.length}}个结果
                </div>
                <div id="c745f9" class="  u80c80">
                </div>
                <el-button id="c5e9cb" @click="addColumnsDialog()" type="primary">添加列
                </el-button>
            </div>
            <div id="c510a8" class="  ub3b98">
                <div id="c2461a" class=" uhrGmwl">
                    <div id="ca5e9a" @mousemove="resizeColumn($event)" @mouseup="stopResize()" @mouseleave="stopResize()" class=" udffZn7">
                        <!-- 表格内容 -->
                        <table id="c421aa" class=" uBd6oPP">
                            <thead id="cd8aba" :style="isNoHeader?{visibility:'collapse'}:{}" class=" uWAboi3">
                                <tr id="ca9a58" class=" uu1wVJG">
                                    <th id="ca1d39" v-show="isOpenSlct" class=" uuFPbOx  u7b006" >
                                        <el-checkbox id="c0b733" v-model="checked" label @change="changeSlctAll">
                                        </el-checkbox>
                                        <div id="c21cbe" class=" resize-handle  ufe0c1">|
                                        </div>
                                    </th>
                                    <th id="c29a09" v-if="isOpenFold" class=" uuFPbOx    " >
                                        <div id="c8b773" class=" resize-handle  ufe0c1  ">|
                                        </div>
                                    </th>
                                    <th id="c92bfc" v-for="(column, index) in columns" :style="{ width: column.width + 'px' }" class=" uuFPbOx" :key="index">
                                        <span id="c5dab2" class=" uXGF1oN" :draggable="true" @dragstart="startDrag(index)" @dragover="dragOver(index)" @drop="drop">{{ column.title }}
                                        </span>
                                        <div id="c351a6" @mousedown="startResize(index, $event)" class=" resize-handle">|
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody id="c1c2a7" class=" ukqVKen">
                                <template id="cf1ec9" v-for="(item,index) in data" >
                                    <tr id="cd8dc4" :key="index">
                                        <td id="c76a3e" v-show="isOpenSlct" class="  uec244  ">
                                            <el-checkbox id="cee084" v-model="item.isChecked" label @change="changeItemChecked">
                                            </el-checkbox>
                                        </td>
                                        <td id="ccfc0f" v-if="isOpenFold" class=" uec244  u76f0e">
                                            <svg id="ideVSgk7" @click="showChart(item)" :class="chartShowId == item.aaaId?'open_item':''" class="icon uGFGfSn" t="1710903576716" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4296" width="14" height="14">
                                                <path id="id6mzhdL" d="M557.179 904c-8.189 0-16.379-3.124-22.628-9.372-12.496-12.497-12.496-32.759 0-45.256L871.924 512 534.551 174.627c-12.496-12.497-12.496-32.758 0-45.255 12.498-12.497 32.758-12.497 45.256 0l360 360c12.496 12.497 12.496 32.758 0 45.255l-360 360c-6.249 6.249-14.439 9.373-22.628 9.373z" p-id="4297" fill="#515151">
                                                </path>
                                            </svg>
                                        </td>
                                        <td id="c5e1b4" v-for="(item2,index3) in columns" class="  uec244" :key="index3">
                                            <a id="c11af8" @click="showChart(item)" v-if="item2.isShowChart">{{showItem(item,index3)}}
                                                <el-icon id="c0a83f">
                                                    <arrow-down id="cc53ea">
                                                    </arrow-down>
                                                </el-icon>
                                            </a>
                                            <span id="c21587" @click="selectItem(item2,item)" v-else="''" class="  ue2b3b">{{showItem(item,index3)}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr id="cc9667" v-if="chartShowId == item.aaaId" class="  u2fe9d">
                                        <td id="c72472" colspan="100">
                                            <slot id="cf82e1" data="我是数据">
                                            </slot>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <el-pagination id="cdf719" class="  u46689" layout="sizes, prev, pager, next" :total="1000">分页
                </el-pagination>
                <div id="g5fdb1" class="  u1d500">
                    <div id="g3ebe7" class="  ue0127">1-10 of over 1,000 results for 'xxxxx'
                    </div>
                    <div id="g96898" class="  u7dff6">
                        <div id="c62f9b" class="  ue0127  u4d2fd">Rows per page:
                        </div>
                        <div id="gb6882" class="  ua12e9">
                            <el-select id="c9a87c" v-model="selectPagesValue" class="  u43adczz" size="mini">
                                <el-option id="cde6e0" v-for="(item) in selectPagesOptions" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <el-button id="g2155c" class="  u6e5ae" type="primary" size="mini">
                            <svg id="idKBv5ff" class="icon " t="1717131667825" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6282" width="1em" height="1em">
                                <path id="idQ686uc" d="M938.666667 533.333333a21.333333 21.333333 0 0 1-21.333334 21.333334H158.166667l134.253333 134.246666a21.333333 21.333333 0 1 1-30.173333 30.173334l-170.666667-170.666667a21.333333 21.333333 0 0 1 0-30.173333l170.666667-170.666667a21.333333 21.333333 0 0 1 30.173333 30.173333L158.166667 512H917.333333a21.333333 21.333333 0 0 1 21.333334 21.333333z" fill="#1290C9" p-id="6283">
                                </path>
                            </svg>
                        </el-button>
                        <el-button id="g7f174" class=" u6e5ae " type="primary" size="mini">
                            <svg id="idzo5JDt" class="icon " t="1717132089396" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6443" width="1em" height="1em">
                                <path id="idXHaxQ6" d="M85.333333 533.333333a21.333333 21.333333 0 0 0 21.333334 21.333334h759.166666l-134.253333 134.246666a21.333333 21.333333 0 0 0 30.173333 30.173334l170.666667-170.666667a21.333333 21.333333 0 0 0 0-30.173333l-170.666667-170.666667a21.333333 21.333333 0 0 0-30.173333 30.173333L865.833333 512H106.666667a21.333333 21.333333 0 0 0-21.333334 21.333333z" fill="#5C5C66" p-id="6444">
                                </path>
                            </svg>
                        </el-button>
                    </div>
                </div>
            </div>
            <el-dialog id="c3d263" v-model="dialogColumn" title="提示" :visible.sync="dialogColumn">
                <div id="cad8ce" class=" u7be4f">
                    <el-cascader-panel id="c62fe9" v-model="cascader6Value" :options="cascader6Options" ref="cascader" :filterable="true" :props="{ multiple: true }">
                        <template id="c9a35e" #default="{ node, data }">
                            <span id="c50539">
                                <span id="c4ed3e">{{ data.label }}
                                </span>
                            </span>
                            <span id="cbedf2" v-if="!node.isLeaf">
                                <span id="c6161b"> ({{ data.children.length }})
                                </span>
                            </span>
                        </template>
                    </el-cascader-panel>
                </div>
                <div id="c98117" class=" dialog-footer  u31391" slot="footer">
                    <el-button id="c4da3f" @click="dialogColumn = false">取消
                    </el-button>
                    <el-button id="c79f0e" @click="addColunmnsData()" type="primary">确定
                    </el-button>
                </div>
            </el-dialog>
        </div>
    </div>
    <!--srccomponentsgui_tablegui_tablevuehtmlEnd-->

</template>
<script>
    // interfaceCode
    //importStart
    import {
        autoSortArr
    } from '../../utils/index.js'
    //importEnd

    export default {
        name: '',
        props: {
            // srccomponentsgui_tablegui_tablevuePropsStart

            // 不显示自选
            isNoMy: {
                type: Boolean,
                default: false
            },
            // 是否不显示标题
            isNoHeader: {
                type: Boolean,
                default: false
            },
            // 是否开启选择
            isOpenSlct: {
                type: Boolean,
                default: true
            },
            // 是否开启折叠
            isOpenFold: {
                type: Boolean,
                default: false
            },
            // 列配置
            columns: {
                type: Array,
                default: [{
                        "title": "Company Name",
                        "key": "companyName",
                        "isShow": true,
                        "width": 300
                    },
                    {
                        "title": "加入日期",
                        "key": "joiningDate",
                        "isShow": true,
                        "width": 150
                    },
                    {
                        "title": "销售",
                        "key": "sell",
                        "isShow": true,
                        "width": 120
                    },
                    {
                        "title": "标签",
                        "key": "myValue",
                        "width": 180
                    },
                    {
                        "title": "行业",
                        "key": "industry",
                        "isShowChart": true,
                        "isShow": true,
                        "width": 200
                    },
                    {
                        "title": "评级",
                        "key": "rate",
                        "isShow": true,
                        "width": 200
                    },
                    {
                        "title": "状态",
                        "isShow": true,
                        "key": "sell",
                        "width": 200
                    }
                ]
            }
            // srccomponentsgui_tablegui_tablevuePropsEnd


        },

        mounted() {
            // defaultLoad
            // srccomponentsgui_tablegui_tablevueMountedStart

            // srccomponentsgui_tablegui_tablevueMountedEnd


        },
        components: {

        },
        data() {
            return {
                // insertData
                // srccomponentsgui_tablegui_tablevueDataStart

                // proxy
                proxy: this,
                // draggingIndex
                draggingIndex: null,
                // items
                items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
                // 选中的数据
                rowsSlcts: [],
                // resizing
                resizing: false,
                // columnIndex
                columnIndex: 0,
                // startX
                startX: 0,
                // 
                checked: '',
                // 默认的列
                defaultColumns: [{
                        "title": "Company Name",
                        "key": "companyName",
                        "isShow": true,
                        "width": 300
                    },
                    {
                        "title": "加入日期",
                        "key": "joiningDate",
                        "isShow": true,
                        "width": 150
                    },
                    {
                        "title": "销售",
                        "key": "sell",
                        "isShow": true,
                        "width": 120
                    },
                    {
                        "title": "标签",
                        "key": "myValue",
                        "width": 180
                    },
                    {
                        "title": "行业",
                        "key": "industry",
                        "isShowChart": true,
                        "isShow": true,
                        "width": 200
                    },
                    {
                        "title": "评级",
                        "key": "rate",
                        "isShow": true,
                        "width": 200
                    },
                    {
                        "title": "状态",
                        "isShow": true,
                        "key": "sell",
                        "width": 200
                    }
                ],
                // 数据
                data: [{
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": true,
                        "aaaId": "156107587326",
                        "companyName": "商安信(上海)企业发展股份有限公司",
                        "companyNameEn": "3ACredit Joint Stock Co., Ltd.",
                        "joiningDate": "2024-01-11",
                        "particularYear": null,
                        "sell": 47359,
                        "staff": null,
                        "industry": "信用服务",
                        "industryEn": "Credit services",
                        "rate": "B+",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2024-01-11T02:30:28",
                        "updatedBy": "wk52117",
                        "updateTime": "2024-01-11T02:30:28",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "28万",
                        "yingyeshouru": "1200",
                        "zaiyenianshu": "4年",
                        "gongsixingzhi": "外资企业",
                        "shengqu": "上海",
                        "shiqu": "浦东",
                        "xian": "张江",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "大型企业",
                        "renshu": 80000,
                        "kehushu": 300,
                        "zhuanli": 80,
                        "shangbiao": 20,
                        "ruanzhu": 120,
                        "zuozhu": 25,
                        "yinshou": "1200万",
                        "jilirun": "400万",
                        "zongzhichan": "4000万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156034438651",
                        "companyName": "新华人寿保险股份有限公司",
                        "companyNameEn": "New China Life Insurance Company Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 214319000,
                        "staff": null,
                        "industry": "人寿保险",
                        "industryEn": "Life insurance",
                        "rate": "BB-",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T04:17:43",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T04:17:43",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "35万",
                        "yingyeshouru": "1500",
                        "zaiyenianshu": "6年",
                        "gongsixingzhi": "合资企业",
                        "shengqu": "北京",
                        "shiqu": "朝阳",
                        "xian": "亮马桥",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "中型企业",
                        "renshu": 60000,
                        "kehushu": 250,
                        "zhuanli": 50,
                        "shangbiao": 15,
                        "ruanzhu": 100,
                        "zuozhu": 18,
                        "yinshou": "1000万",
                        "jilirun": "300万",
                        "zongzhichan": "3000万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156015905403",
                        "companyName": "上海一冷开利空调设备有限公司",
                        "companyNameEn": "Shanghai Yileng Carrier Air Conditioning Equipment Co., Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 2889148,
                        "staff": null,
                        "industry": "制冷、空调设备制造",
                        "industryEn": "Commercial and industrial refrigeration equipment manufacturing",
                        "rate": "BB-",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T03:18:41",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T03:18:41",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "40万",
                        "yingyeshouru": "1800",
                        "zaiyenianshu": "7年",
                        "gongsixingzhi": "民营企业",
                        "shengqu": "广东",
                        "shiqu": "广州",
                        "xian": "天河",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "大型企业",
                        "renshu": 120000,
                        "kehushu": 400,
                        "zhuanli": 90,
                        "shangbiao": 25,
                        "ruanzhu": 150,
                        "zuozhu": 30,
                        "yinshou": "1500万",
                        "jilirun": "500万",
                        "zongzhichan": "5000万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156087357346",
                        "companyName": "巴斯夫新材料有限公司",
                        "companyNameEn": "BASF New Materials Co., Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 5320410,
                        "staff": null,
                        "industry": "工业颜料制造",
                        "industryEn": "Industrial pigment manufacturing",
                        "rate": "BB",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T03:06:02",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T03:06:02",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "22万",
                        "yingyeshouru": "800",
                        "zaiyenianshu": "3年",
                        "gongsixingzhi": "国有企业",
                        "shengqu": "北京",
                        "shiqu": "西城",
                        "xian": "西单",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "微小企业",
                        "renshu": 100,
                        "kehushu": 150,
                        "zhuanli": 40,
                        "shangbiao": 10,
                        "ruanzhu": 80,
                        "zuozhu": 20,
                        "yinshou": "800万",
                        "jilirun": "250万",
                        "zongzhichan": "2500万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156047011308",
                        "companyName": "巴斯夫聚氨酯（重庆）有限公司",
                        "companyNameEn": "BASF Polyurethane (Chongqing) Co., Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 6229518,
                        "staff": null,
                        "industry": "有机化学原料制造",
                        "industryEn": "Raw organic chemical material manufacturing",
                        "rate": "BB+",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T03:04:58",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T03:04:58",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "22万",
                        "yingyeshouru": "800",
                        "zaiyenianshu": "3年",
                        "gongsixingzhi": "国有企业",
                        "shengqu": "北京",
                        "shiqu": "西城",
                        "xian": "西单",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "微小企业",
                        "renshu": 1000,
                        "kehushu": 150,
                        "zhuanli": 40,
                        "shangbiao": 10,
                        "ruanzhu": 80,
                        "zuozhu": 20,
                        "yinshou": "800万",
                        "jilirun": "250万",
                        "zongzhichan": "2500万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156022228606",
                        "companyName": "苏州尚美国际化妆品有限公司",
                        "companyNameEn": "Suzhou Shangmei International Cosmetics Co., Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 3002924,
                        "staff": null,
                        "industry": "化妆品制造",
                        "industryEn": "Cosmetics manufacturing",
                        "rate": "BB",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T02:56:45",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T02:56:45",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "22万",
                        "yingyeshouru": "800",
                        "zaiyenianshu": "3年",
                        "gongsixingzhi": "国有企业",
                        "shengqu": "北京",
                        "shiqu": "西城",
                        "xian": "西单",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "微小企业",
                        "renshu": 30000,
                        "kehushu": 150,
                        "zhuanli": 40,
                        "shangbiao": 10,
                        "ruanzhu": 80,
                        "zuozhu": 20,
                        "yinshou": "800万",
                        "jilirun": "250万",
                        "zongzhichan": "2500万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": true,
                        "aaaId": "156107587326",
                        "companyName": "商安信(上海)企业发展股份有限公司",
                        "companyNameEn": "3ACredit Joint Stock Co., Ltd.",
                        "joiningDate": "2024-01-11",
                        "particularYear": null,
                        "sell": 47359,
                        "staff": null,
                        "industry": "信用服务",
                        "industryEn": "Credit services",
                        "rate": "B+",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2024-01-11T02:30:28",
                        "updatedBy": "wk52117",
                        "updateTime": "2024-01-11T02:30:28",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "28万",
                        "yingyeshouru": "1200",
                        "zaiyenianshu": "4年",
                        "gongsixingzhi": "外资企业",
                        "shengqu": "上海",
                        "shiqu": "浦东",
                        "xian": "张江",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "大型企业",
                        "renshu": 80000,
                        "kehushu": 300,
                        "zhuanli": 80,
                        "shangbiao": 20,
                        "ruanzhu": 120,
                        "zuozhu": 25,
                        "yinshou": "1200万",
                        "jilirun": "400万",
                        "zongzhichan": "4000万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156034438651",
                        "companyName": "新华人寿保险股份有限公司",
                        "companyNameEn": "New China Life Insurance Company Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 214319000,
                        "staff": null,
                        "industry": "人寿保险",
                        "industryEn": "Life insurance",
                        "rate": "BB-",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T04:17:43",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T04:17:43",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "35万",
                        "yingyeshouru": "1500",
                        "zaiyenianshu": "6年",
                        "gongsixingzhi": "合资企业",
                        "shengqu": "北京",
                        "shiqu": "朝阳",
                        "xian": "亮马桥",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "中型企业",
                        "renshu": 60000,
                        "kehushu": 250,
                        "zhuanli": 50,
                        "shangbiao": 15,
                        "ruanzhu": 100,
                        "zuozhu": 18,
                        "yinshou": "1000万",
                        "jilirun": "300万",
                        "zongzhichan": "3000万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156015905403",
                        "companyName": "上海一冷开利空调设备有限公司",
                        "companyNameEn": "Shanghai Yileng Carrier Air Conditioning Equipment Co., Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 2889148,
                        "staff": null,
                        "industry": "制冷、空调设备制造",
                        "industryEn": "Commercial and industrial refrigeration equipment manufacturing",
                        "rate": "BB-",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T03:18:41",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T03:18:41",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "40万",
                        "yingyeshouru": "1800",
                        "zaiyenianshu": "7年",
                        "gongsixingzhi": "民营企业",
                        "shengqu": "广东",
                        "shiqu": "广州",
                        "xian": "天河",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "大型企业",
                        "renshu": 120000,
                        "kehushu": 400,
                        "zhuanli": 90,
                        "shangbiao": 25,
                        "ruanzhu": 150,
                        "zuozhu": 30,
                        "yinshou": "1500万",
                        "jilirun": "500万",
                        "zongzhichan": "5000万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156087357346",
                        "companyName": "巴斯夫新材料有限公司",
                        "companyNameEn": "BASF New Materials Co., Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 5320410,
                        "staff": null,
                        "industry": "工业颜料制造",
                        "industryEn": "Industrial pigment manufacturing",
                        "rate": "BB",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T03:06:02",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T03:06:02",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "22万",
                        "yingyeshouru": "800",
                        "zaiyenianshu": "3年",
                        "gongsixingzhi": "国有企业",
                        "shengqu": "北京",
                        "shiqu": "西城",
                        "xian": "西单",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "微小企业",
                        "renshu": 100,
                        "kehushu": 150,
                        "zhuanli": 40,
                        "shangbiao": 10,
                        "ruanzhu": 80,
                        "zuozhu": 20,
                        "yinshou": "800万",
                        "jilirun": "250万",
                        "zongzhichan": "2500万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156047011308",
                        "companyName": "巴斯夫聚氨酯（重庆）有限公司",
                        "companyNameEn": "BASF Polyurethane (Chongqing) Co., Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 6229518,
                        "staff": null,
                        "industry": "有机化学原料制造",
                        "industryEn": "Raw organic chemical material manufacturing",
                        "rate": "BB+",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T03:04:58",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T03:04:58",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "22万",
                        "yingyeshouru": "800",
                        "zaiyenianshu": "3年",
                        "gongsixingzhi": "国有企业",
                        "shengqu": "北京",
                        "shiqu": "西城",
                        "xian": "西单",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "微小企业",
                        "renshu": 1000,
                        "kehushu": 150,
                        "zhuanli": 40,
                        "shangbiao": 10,
                        "ruanzhu": 80,
                        "zuozhu": 20,
                        "yinshou": "800万",
                        "jilirun": "250万",
                        "zongzhichan": "2500万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156022228606",
                        "companyName": "苏州尚美国际化妆品有限公司",
                        "companyNameEn": "Suzhou Shangmei International Cosmetics Co., Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 3002924,
                        "staff": null,
                        "industry": "化妆品制造",
                        "industryEn": "Cosmetics manufacturing",
                        "rate": "BB",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T02:56:45",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T02:56:45",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "22万",
                        "yingyeshouru": "800",
                        "zaiyenianshu": "3年",
                        "gongsixingzhi": "国有企业",
                        "shengqu": "北京",
                        "shiqu": "西城",
                        "xian": "西单",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "微小企业",
                        "renshu": 30000,
                        "kehushu": 150,
                        "zhuanli": 40,
                        "shangbiao": 10,
                        "ruanzhu": 80,
                        "zuozhu": 20,
                        "yinshou": "800万",
                        "jilirun": "250万",
                        "zongzhichan": "2500万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": true,
                        "aaaId": "156107587326",
                        "companyName": "商安信(上海)企业发展股份有限公司",
                        "companyNameEn": "3ACredit Joint Stock Co., Ltd.",
                        "joiningDate": "2024-01-11",
                        "particularYear": null,
                        "sell": 47359,
                        "staff": null,
                        "industry": "信用服务",
                        "industryEn": "Credit services",
                        "rate": "B+",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2024-01-11T02:30:28",
                        "updatedBy": "wk52117",
                        "updateTime": "2024-01-11T02:30:28",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "28万",
                        "yingyeshouru": "1200",
                        "zaiyenianshu": "4年",
                        "gongsixingzhi": "外资企业",
                        "shengqu": "上海",
                        "shiqu": "浦东",
                        "xian": "张江",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "大型企业",
                        "renshu": 80000,
                        "kehushu": 300,
                        "zhuanli": 80,
                        "shangbiao": 20,
                        "ruanzhu": 120,
                        "zuozhu": 25,
                        "yinshou": "1200万",
                        "jilirun": "400万",
                        "zongzhichan": "4000万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156034438651",
                        "companyName": "新华人寿保险股份有限公司",
                        "companyNameEn": "New China Life Insurance Company Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 214319000,
                        "staff": null,
                        "industry": "人寿保险",
                        "industryEn": "Life insurance",
                        "rate": "BB-",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T04:17:43",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T04:17:43",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "35万",
                        "yingyeshouru": "1500",
                        "zaiyenianshu": "6年",
                        "gongsixingzhi": "合资企业",
                        "shengqu": "北京",
                        "shiqu": "朝阳",
                        "xian": "亮马桥",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "中型企业",
                        "renshu": 60000,
                        "kehushu": 250,
                        "zhuanli": 50,
                        "shangbiao": 15,
                        "ruanzhu": 100,
                        "zuozhu": 18,
                        "yinshou": "1000万",
                        "jilirun": "300万",
                        "zongzhichan": "3000万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156015905403",
                        "companyName": "上海一冷开利空调设备有限公司",
                        "companyNameEn": "Shanghai Yileng Carrier Air Conditioning Equipment Co., Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 2889148,
                        "staff": null,
                        "industry": "制冷、空调设备制造",
                        "industryEn": "Commercial and industrial refrigeration equipment manufacturing",
                        "rate": "BB-",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T03:18:41",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T03:18:41",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "40万",
                        "yingyeshouru": "1800",
                        "zaiyenianshu": "7年",
                        "gongsixingzhi": "民营企业",
                        "shengqu": "广东",
                        "shiqu": "广州",
                        "xian": "天河",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "大型企业",
                        "renshu": 120000,
                        "kehushu": 400,
                        "zhuanli": 90,
                        "shangbiao": 25,
                        "ruanzhu": 150,
                        "zuozhu": 30,
                        "yinshou": "1500万",
                        "jilirun": "500万",
                        "zongzhichan": "5000万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156087357346",
                        "companyName": "巴斯夫新材料有限公司",
                        "companyNameEn": "BASF New Materials Co., Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 5320410,
                        "staff": null,
                        "industry": "工业颜料制造",
                        "industryEn": "Industrial pigment manufacturing",
                        "rate": "BB",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T03:06:02",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T03:06:02",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "22万",
                        "yingyeshouru": "800",
                        "zaiyenianshu": "3年",
                        "gongsixingzhi": "国有企业",
                        "shengqu": "北京",
                        "shiqu": "西城",
                        "xian": "西单",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "微小企业",
                        "renshu": 100,
                        "kehushu": 150,
                        "zhuanli": 40,
                        "shangbiao": 10,
                        "ruanzhu": 80,
                        "zuozhu": 20,
                        "yinshou": "800万",
                        "jilirun": "250万",
                        "zongzhichan": "2500万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156047011308",
                        "companyName": "巴斯夫聚氨酯（重庆）有限公司",
                        "companyNameEn": "BASF Polyurethane (Chongqing) Co., Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 6229518,
                        "staff": null,
                        "industry": "有机化学原料制造",
                        "industryEn": "Raw organic chemical material manufacturing",
                        "rate": "BB+",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T03:04:58",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T03:04:58",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "22万",
                        "yingyeshouru": "800",
                        "zaiyenianshu": "3年",
                        "gongsixingzhi": "国有企业",
                        "shengqu": "北京",
                        "shiqu": "西城",
                        "xian": "西单",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "微小企业",
                        "renshu": 1000,
                        "kehushu": 150,
                        "zhuanli": 40,
                        "shangbiao": 10,
                        "ruanzhu": 80,
                        "zuozhu": 20,
                        "yinshou": "800万",
                        "jilirun": "250万",
                        "zongzhichan": "2500万"
                    },
                    {
                        "accountId": "108",
                        "isChecked": false,
                        "isShowChart": false,
                        "aaaId": "156022228606",
                        "companyName": "苏州尚美国际化妆品有限公司",
                        "companyNameEn": "Suzhou Shangmei International Cosmetics Co., Ltd.",
                        "joiningDate": "2023-11-17",
                        "particularYear": null,
                        "sell": 3002924,
                        "staff": null,
                        "industry": "化妆品制造",
                        "industryEn": "Cosmetics manufacturing",
                        "rate": "BB",
                        "ideaType": "0",
                        "systemValue": null,
                        "systemValueEn": null,
                        "myValue": null,
                        "weight": null,
                        "massesValue": null,
                        "tag": null,
                        "otherTag": null,
                        "dateTime": null,
                        "createdBy": "wk52117",
                        "createTime": "2023-11-17T02:56:45",
                        "updatedBy": "wk52117",
                        "updateTime": "2023-11-17T02:56:45",
                        "orderCode": null,
                        "orderStatus": null,
                        "orderStatusCn": null,
                        "orderStatusEn": null,
                        "zhuceziben": "22万",
                        "yingyeshouru": "800",
                        "zaiyenianshu": "3年",
                        "gongsixingzhi": "国有企业",
                        "shengqu": "北京",
                        "shiqu": "西城",
                        "xian": "西单",
                        "gongshizhuangtai": "运营",
                        "shangshi": "是",
                        "fazai": "是",
                        "guimo": "微小企业",
                        "renshu": 30000,
                        "kehushu": 150,
                        "zhuanli": 40,
                        "shangbiao": 10,
                        "ruanzhu": 80,
                        "zuozhu": 20,
                        "yinshou": "800万",
                        "jilirun": "250万",
                        "zongzhichan": "2500万"
                    }
                ],
                // 
                chartShowId: '',
                // 下拉框选中的内容
                selectPagesValue: '5',
                // 下拉框列表
                selectPagesOptions: [{
                        value: '1',
                        label: '1',
                    },
                    {
                        value: '2',
                        label: '2',
                    },
                    {
                        value: '3',
                        label: '3',
                    },
                    {
                        value: '4',
                        label: '4',
                    },
                    {
                        value: '5',
                        label: '5',
                    },
                ],
                // 
                dialogColumn: false,
                // 级联选择器配置
                cascader6Props: {
                    /**
                     * 次级菜单的展开方式
                     * @type {ExpandTrigger}
                     */
                    expandTrigger: 'click', // 有click点击，hover停靠

                    /**
                     * 是否多选
                     * @type {Multiple}
                     */
                    multiple: true,

                    /**
                     * 是否严格的遵守父子节点不互相关联
                     * @type {CheckStrictly}
                     */
                    checkStrictly: false,

                    /**
                     * 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
                     * @type {EmitPath}
                     */
                    emitPath: true,

                    /**
                     * 是否动态加载子节点，需与 lazyLoad 方法结合使用
                     * @type {Lazy}
                     */
                    lazy: false,

                    /**
                     * 加载动态数据的方法，仅在 lazy 为 true 时有效
                     * @type {LazyLoad}
                     */
                    lazyLoad: () => {},

                    /**
                     * 指定选项的值为选项对象的某个属性值
                     * @type {ValueKey}
                     */
                    value: 'value',

                    /**
                     * 指定选项标签为选项对象的某个属性值
                     * @type {LabelKey}
                     */
                    label: 'label',

                    /**
                     * 指定选项的子选项为选项对象的某个属性值
                     * @type {ChildrenKey}
                     */
                    children: 'children',

                    /**
                     * 指定选项的禁用为选项对象的某个属性值
                     * @type {string}
                     */
                    disabled: 'disabled',

                    /**
                     * 指定选项的叶子节点的标志位为选项对象的某个属性值
                     * @type {string}
                     */
                    leaf: 'leaf',

                    /**
                     * hover 时展开菜单的灵敏度阈值
                     * @type {number}
                     */
                    hoverThreshold: 500,
                },
                // 级联选中的值
                cascader6Value: '',
                // 级联列表
                cascader6Options: [{
                        value: "xingzhilei",
                        label: "性质类",
                        children: [{
                                value: "zhuceziben",
                                label: "注册资本",
                            },
                            {
                                value: "yingyeshouru",
                                label: "营业收入",
                            },
                            {
                                value: "zaiyenianshu",
                                label: "在业年数",
                            },
                            {
                                value: "gongsixingzhi",
                                label: "公司性质",
                            },
                        ],
                    },
                    {
                        value: "diqu",
                        label: "地区类",
                        children: [{
                                value: "shengqu",
                                label: "省区",
                            },
                            {
                                value: "shiqu",
                                label: "市区",
                            },
                            {
                                value: "xian",
                                label: "县",
                            },
                        ],
                    },
                    {
                        value: "zhuangtai",
                        label: "状态类",
                        children: [{
                                value: "gongshizhuangtai",
                                label: "公司状态"
                            },
                            {
                                value: "shangshi",
                                label: "是否上市"
                            },
                            {
                                value: "fazai",
                                label: "是否发债"
                            },
                        ],
                    },
                    {
                        value: "jingying",
                        label: "经营类",
                        children: [{
                                value: "guimo",
                                label: "公司规模"
                            },
                            {
                                value: "renshu",
                                label: "员工人数"
                            },
                            {
                                value: "kehushu",
                                label: "客户数"
                            },
                            {
                                value: "zhuanli",
                                label: "专利数"
                            },
                            {
                                value: "shangbiao",
                                label: "商标数"
                            },
                            {
                                value: "ruanzhu",
                                label: "软著数"
                            },
                            {
                                value: "zuozhu",
                                label: "作著数"
                            },
                        ],
                    }, {
                        value: "caiwu",
                        label: "财务类",
                        children: [{
                                value: "yinshou",
                                label: "营收额"
                            },
                            {
                                value: "jilirun",
                                label: "净利润"
                            },
                            {
                                value: "zongzhichan",
                                label: "总资产"
                            },
                        ],
                    },
                ]

                // srccomponentsgui_tablegui_tablevueDataEnd

            }
        },
        methods: {
            default () {

            },
            // insertMethod
            // srccomponentsgui_tablegui_tablevueMethodStart
            // startDrag
            startDrag(index) {
                this.draggingIndex = index;
            }, // dragOver
            dragOver(index) {
                event.preventDefault();
                if (this.draggingIndex !== null && this.draggingIndex !== index) {
                    // 在拖拽元素和目标元素之间插入占位符
                    const draggedItem = this.columns[this.draggingIndex];
                    this.columns.splice(this.draggingIndex, 1);
                    this.columns.splice(index, 0, draggedItem);
                    this.draggingIndex = index;
                }

            }, // drop
            drop() {
                this.draggingIndex = null;
            }, // 添加列弹框
            addColumnsDialog() {
                this.dialogColumn = true
            }, // 切换选择
            changeSlctAll(value) {
                this.data.forEach(item => {
                    item.isChecked = value
                })
                this.rowsSlcts = this.data.filter(item => item.isChecked)
                this.$emit('slct_rows', this.rowsSlcts)
            }, // startResize
            startResize(index, event) {
                this.resizing = true;
                this.columnIndex = index;
                this.startX = event.clientX;

            }, // resizeColumn
            resizeColumn(event) {
                if (this.resizing) {
                    const deltaX = event.clientX - this.startX;
                    this.columns[this.columnIndex].width += deltaX;
                    this.startX = event.clientX;
                }

            }, // stopResize
            stopResize() {
                this.resizing = false;
                this.columnIndex = null;

            }, // 列表的选中变化监听
            changeItemChecked(value) {
                if ((this.data.filter(item => item.isChecked)).length == this.data.length) {
                    this.checked = true
                } else {
                    this.checked = false
                }
    
                this.rowsSlcts = this.data.filter(item => item.isChecked)
             
                this.$emit('slct_rows', this.rowsSlcts)
                this.$emit('aaa', this.rowsSlcts)
            }, // 展示图表
            showChart(item) {
                if (this.chartShowId == item.aaaId) {
                    this.chartShowId = ''
                } else {
                    this.chartShowId = item.aaaId

                }
                this.$emit('open_content', this.chartShowId) // 展开节点回调
            }, // 点击列
            selectItem(titleItem, item) {
            
                this.$emit('select_item', titleItem.key, item)
            }, // 
            showItem(item, index3) {
                return item[this.columns.map(item2 => item2.key)[index3]]
            }, // 增加列数据
            addColunmnsData(cascader6Value) {
                function findLabelById(data, id) {
                    for (let i = 0; i < data.length; i++) {
                        const category = data[i];
                        for (let j = 0; j < category.children.length; j++) {
                            const item = category.children[j];
                            if (item.value === id) {
                                return item.label;
                            }
                        }
                    }
                    return null; // 如果未找到匹配的项，则返回null
                }

                if (!cascader6Value) {
                    cascader6Value = this.cascader6Value
                } else {
                    this.cascader6Value = cascader6Value // 赋值
                }
                let arr = cascader6Value.map(item => {
                    let id = item[item.length - 1]
                    return {
                        title: findLabelById(this.cascader6Options, id),
                        key: id,
                        width: 120
                    }
                })


                // this.columns
                // this.columns = [...this.defaultColumns, ...arr]

                this.columns = autoSortArr(this.defaultColumns, this.columns, arr)

                // let current = this.columns
                // let addArr = arr
                // arr = this.defaultColumns
                // let newArr = [...new Set([...current.map(item=>item.key),...addArr.map(item=>item.key)])]
                // newArr = newArr.filter(item=>(addArr.map(item=>item.key).includes(item)||arr.map(item=>item.key).includes(item)))
                // let arrs = [...new Set([...current,...addArr])]
                // newArr = newArr.map(item=> arrs.find(item2=>item2.key == item))
                // this.columns = arrs
                this.dialogColumn = false
            }
            // srccomponentsgui_tablegui_tablevueMethodEnd

            // methodsCode

        },
        computed: {}
    }
</script>
<style>
    .srccomponentsgui_tablegui_tablevuecssStart {}

    .uefd81 {
        width: 100%;
        position: static;
    }

    .ua9186 {
        height: 100%;
    }

    .u23a98 {
        display: none;
        align-items: center;
        padding-top: 0.5em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        padding-bottom: 0.5em;
    }

    .u6edbf {
        margin-right: 0.5em;
    }

    .ud0598 {
        font-size: 12px;
        color: rgba(133, 131, 131, 1);
    }

    .u80c80 {
        flex: auto;
    }

    .ub3b98 {
        height: calc(100% - 50px);
        position: static;
    }

    .uhrGmwl {
        height: 100%;
    }

    .udffZn7 {
        width: max-content;
        height: 100%;
        border-style: solid;
        border-color: rgba(238, 235, 235, 1);
        border-width: 1px;
        border-bottom-left-radius: 0.5em;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .uBd6oPP {
        display: block;
        width: max-content;
        border-collapse: collapse;
        overflow-x: initial;
        flex-direction: column;
    }

    .uWAboi3 {
        position: static;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: #ccc;
    }

    .uu1wVJG {
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: rgba(238, 235, 235, 1);
        background-color: rgba(242, 245, 249, 1);
    }

    .u7b006 {
        width: 14px;
    }

    .ufe0c1 {
        cursor: default !important;
    }

    .uuFPbOx {
        text-align: left;
        height: 3.785em;
        position: relative;
        padding-top: 1em;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 1em;
        box-sizing: border-box;
    }

    .uXGF1oN {
        color: rgba(14, 14, 14, 1);
        font-size: 1.1428em;
    }

    .resize-handle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -8px;
        cursor: col-resize;
        color: rgba(238, 235, 235, 1);
    }

    .ukqVKen {
        background-color: rgba(252, 252, 252, 1);
        overflow-x: initial;
        overflow-y: auto;
        flex: none;
    }

    .uGFGfSn {
        transform: rotate(90deg);
    }

    .uec244 {
        padding-top: 1em;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 1em;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: rgba(238, 235, 235, 1);
        border-right-style: none;
        border-right-color: rgba(238, 235, 235, 1);
        border-right-width: 1px;
        ;
    }

    .ue2b3b {
        font-size: 1.1428em;
    }

    .u2fe9d {
        width: 100%;
    }

    .u46689 {
        display: none;
        margin-top: 0.5em;
        margin-left: -8px;
    }

    .u1d500 {
        display: none;
        justify-content: space-between;
        align-items: center;
        width: 38.2em;
        margin-top: 0.8em;
        margin-bottom: 1.2em;
    }

    .ue0127 {
        color: rgba(132, 151, 171, 1);
    }

    .u7dff6 {
        display: flex;
        align-items: center;
        height: 20px;
    }

    .u4d2fd {
        margin-right: 0.5em;
    }

    .ua12e9 {
        display: flex;
        width: 3.9em;
        margin-right: 1em;
    }

    .u6e5ae {
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 1);
        border-style: solid;
        border-width: 1px;
        border-color: #D9D9D9;
    }

    .u7be4f {
        margin-top: 15px;
    }

    .u31391 {
        margin-top: 15px;
    }

    .uuFPbOx:last-child .resize-handle {
        display: flex;
    }

    .u76f0e .open_item {
        transform: rotate(270deg);
        top: 5px;
        position: relative;
    }

    .uec244:last-child {
        border-right-style: none;
    }

    .u43adczz input {
        color: #1290C9
    }

    .srccomponentsgui_tablegui_tablevuecssEnd {}


    /*cssStart*/

    /*cssEnd*/
</style>